import React, {
  useState,
  useRef,
  SyntheticEvent,
  useEffect,
  useMemo,
} from "react";
import {
  Menu,
  Button,
  Divider,
  Form,
  Input,
  DropdownProps,
  Tab,
  TabProps,
  Checkbox,
  Grid,
  Image,
  Icon,
} from "semantic-ui-react";
import { QueueController } from "react-lib/apps/QUE/Time";
import "react-lib/apps/QUE/Time.css";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import SwipeableViews from "react-swipeable-views";
import "react-lib/css/PatientSelectAppointmentIH.scss";
import personImg from "react-lib/apps/common/person.png";
import { makeStyles } from "@mui/styles";
import { error } from "console";
import MobHeader from "react-lib/apps/IsHealth/IHMobile/MobHeader";
import { useIntl } from "react-intl";

interface RouterProps {
  // type for `match.params`
  appointId: string; // must be type `string` since value comes from the URL
  appointStatus: string; // must be type `string` since value comes from the URL
}

interface IshealthPatientSelectAppointmentProps
  extends RouteComponentProps<RouterProps, any, { channelId?: string }> {
  apiToken?: string;
  controller?: QueueController;
  noApiToken?: () => {};
  divisionId?: number | string;
  intl?: IntlShape;
  divisionList?: any;
  doctorList?: any;
  setProps?: any;
  onEvent?: any;
  appointmentData?: any;
}

const useStyles = makeStyles((theme) => ({
  panes: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  cadeBox: {
    borderRadius: "10px",
    margin: "10px",
    boxSizing: "border-box",
    border: "0.5px solid rgba(227,239,255,0.5)",
    boxShadow: "3px 3px 0px rgba(227,239,255,0.5)",
  },
}));

const COLOR = {
  primary: "var(--primary-theme-color)",
  primary_font: "var(--primary-font-color)",
  grey: `#9e9e9e`,
};

const IMAGE = {
  imageBgUpload: "public/logo_ishealth_circle.png",
};

const IshealthPatientSelectAppointment = (
  props: IshealthPatientSelectAppointmentProps
) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [divisionList, setDivisionList] = useState();
  const [doctorList, setDoctorList] = useState();
  const [viewIndex, setViewIndex] = useState<number | string>(0);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [detail, setDetail] = useState<boolean>(false);
  const [errorInput, setErrorInput] = useState<boolean>(false);
  const [searchDivision, setSearchDivision] = useState("");
  const [isTelemed, setIsTelemed] = useState<boolean>(false);
  const containerRef = useRef<any>();
  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="doctor" className={classes.panes}>
            แพทย์
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="department" className={classes.panes}>
            แผนก
          </Menu.Item>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    props.onEvent({
      message: "HandleDivisionList",
      params: { only_have_dsb: true, detail: props.appointmentData?.detail },
    });

    // if (!props.newUI) {
    //   props.onEvent({
    //     message: "HandleDoctorList",
    //     params: {},
    //   });
    // }

    // if (props.appointmentData?.detail) {
    //   setDetail(true);
    // }
  }, []);

  useEffect(() => {
    setDivisionList(props.divisionList);
    setDoctorList(props.doctorList);
  }, [props.divisionList, props.doctorList]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isShowDetail = params.get("show-detail") === "true";
    const tabIndex = params.get("tab-index");

    setDetail(isShowDetail);
    setViewIndex(Number(tabIndex) || 0);
  }, [location.search]);

  const handleCreateAppointmentIH = (status: any, id: any) => {
    history.push(`/createAppointmentIH/${status}/${id}/`, props.location.state);
  };

  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    handleChangeIndex(Number(data.activeIndex));
  };

  const handleChangeIndex = (index: number) => {
    const params = new URLSearchParams(location.search);
    params.set("tab-index", index.toString());

    history.replace({ search: params.toString() });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDoctorSearchChange = (event: any, data: any) => {
    if (!data.value) {
      props.onEvent({
        message: "HandleDoctorList",
        params: { isTelemed },
      });
    } else if (data.value.length > 2) {
      props.onEvent({
        message: "HandleDoctorList",
        params: { search: data.value, isTelemed },
      });
    }
    setSearchDoctor(data.value);
  };

  const handleCheckedIsTelemed = (event: any, data: any) => {
    props.onEvent({
      message: "HandleDoctorList",
      params: { isTelemed: data.checked },
    });
    props.setProps(`appointmentData.isTelemed`, data.checked);
    setIsTelemed(data.checked);
  };

  const handleCheckedDataDetail = () => {
    // if (props?.appointmentData?.detail.length > 1){
    // setDetail(true);
    // } else {
    //   setErrorInput(true)
    // }
    history.push("/appointmentIH/?show-detail=true");
  };

  console.log(
    "saika ~ IshealthPatientSelectAppointment ~ doctorList:",
    doctorList
  );

  return (
    <div className="content-header">
      <MobHeader
        title={intl.formatMessage({ id: "นัดหมาย" })}
        history={props.history}
        leftIconClick={() => {
          // if (detail) {
          //   setDetail(false);
          // }

          props.history.goBack();
        }}
      />
      <div className="content-detail">
        <div style={{ padding: "0px 3% 3% 3%" }}>
          {!detail ? (
            <div style={{ margin: "20px" }}>
              <div
                className="content-header-laban"
                style={{ marginTop: "25%" }}
              >
                {"ระบุอาการเบื้องต้น"}
              </div>
              <div className="content-input-detail">
                <Input
                  fluid={true}
                  onChange={(_event: any, data: any) => {
                    props.setProps(`appointmentData.detail`, data.value);
                  }}
                  value={props?.appointmentData?.detail || ""}
                  placeholder={
                    "เพื่อความรวดเร็ว กรุณาระบุรายละเอียดอาการ"
                  }
                  // onClick={()=> setErrorInput(false)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  className="content-button "
                  onClick={handleCheckedDataDetail}
                >
                  {"ดำเนินการต่อ"}
                </div>
              </div>
            </div>
          ) : (
            <>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                activeIndex={viewIndex}
                onTabChange={handleTabChange}
                className="tab-custom"
                style={{ marginTop: "10px" }}
              />
              <SwipeableViews
                ref={containerRef}
                index={+viewIndex}
                onChangeIndex={handleChangeIndex}
                slideClassName="cheack-view"
                style={{ padding: "0px 5px" }}
              >
                <div>
                  {/* search */}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px 0px",
                      }}
                    >
                      <div
                        className={"appoint-search"}
                        style={{ width: "90%" }}
                      >
                        <Input
                          icon="search"
                          iconPosition="left"
                          placeholder={intl.formatMessage({ id: "ค้นหาแพทย์" })}
                          size="mini"
                          fluid={true}
                          value={searchDoctor}
                          onChange={handleDoctorSearchChange}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "95%",
                      }}
                    >
                      <Checkbox
                        style={{ margin: "0px 10px" }}
                        checked={isTelemed}
                        onChange={handleCheckedIsTelemed}
                      />
                      <div
                        style={{
                          color: "#1AB3CB",
                          fontWeight: "bold",
                        }}
                      >
                        Telemed
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  {doctorList
                    // ?.filter((item: any) => item?.full_name.includes(searchDoctor))
                    ?.map((item: any, index: any) => {
                      return (
                        <div
                          style={{
                            margin: "15px 5px",
                          }}
                          onClick={() =>
                            handleCreateAppointmentIH("doctor", item?.id)
                          }
                        >
                          <div className={"appointment-card-box"}>
                            <div
                              style={{
                                display: "flex",
                                padding: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "25%", margin: "5px" }}>
                                <Image
                                  src={item?.image || personImg}
                                  style={{ borderRadius: "10px" }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "75%",
                                  margin: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#1ABDD4",

                                    fontWeight: "bold",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  {item?.full_name}
                                </div>
                                <div
                                  style={{
                                    color: COLOR.grey,
                                    paddingBottom: "5px",
                                  }}
                                >
                                  {item?.divisions[0]?.name || ""}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="card-appointment-telemed"
                                    style={{
                                      background: "#96CF9E",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <Icon name="hospital outline" />
                                    <div>{"OPD"}</div>
                                  </div>
                                  <>
                                    {item?.is_telemed && (
                                      <div
                                        className="card-appointment-telemed"
                                        style={{ background: "#F493AC" }}
                                      >
                                        <Icon name="video camera" />
                                        <div>{"Telemedicine"}</div>
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  {/* search */}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px 0px",
                      }}
                    >
                      <div
                        className={"appoint-search"}
                        style={{ width: "90%" }}
                      >
                        <Input
                          icon="search"
                          iconPosition="left"
                          placeholder={intl.formatMessage({ id: "ค้นหาแผนก" })}
                          size="mini"
                          fluid={true}
                          value={searchDivision}
                          onChange={(e: any, data: any) =>
                            setSearchDivision(data.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  {divisionList
                    ?.filter((item: any) => item?.name.includes(searchDivision))
                    ?.map((item: any, index: any) => {
                      return (
                        <div
                          style={{ margin: "15px 0px" }}
                          onClick={() =>
                            handleCreateAppointmentIH("division", item?.id)
                          }
                        >
                          <div className={"appointment-card-box"}>
                            <div
                              style={{
                                display: "flex",
                                padding: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="division"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Image
                                  src={personImg}
                                  size="mini"
                                  style={{
                                    border: "1px solid rgba(227,239,255,0.5)",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "80%",
                                  margin: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "30px",
                                    fontWeight: "bold",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  {item?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </SwipeableViews>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

IshealthPatientSelectAppointment.defaultProps = {};

export default React.memo(
  injectIntl(IshealthPatientSelectAppointment, { forwardRef: true })
);
