import React, { Component, createRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import "../css/Default.css";
import Cookies from "js-cookie";
import Fingerprint2 from "fingerprintjs2";
import { vcMessenger } from "react-lib/compat/vc-websocket";
import CONFIG from "config/config";
import {
  askUserPermission,
  createNotificationSubscription,
} from "react-lib/apps/IsHealth/Common/push-notifications";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import "semantic-ui-css/semantic.min.css";

// Interface
import * as serviceWorker from "../serviceWorker";
import * as MainVideoCall from "../MainVideoCall";
import * as MobIsHealthInterface from "./MobIsHealthInterface";
// Controller
import MobIsHealthController from "./MobIsHealthController";
// Framwords
import SetProp from "../react-lib/frameworks/SetProp";

// Manager
import MixInManager from "react-lib/apis/manager/MixInManager";
import PRXManager from "react-lib/apis/manager/PRXManager";
import ChatManager from "apis/ChatManager";
import DiagManager from "apis/DiagManager";
import UserManager from "apis/UserManager";
import QUEManager from "react-lib/apis/manager/QUEManager";
import QueueController from "../react-lib/apps/QUE/QueueController";
import CoreManager from "react-lib/apis/manager/CoreManager";
import BILManager from "react-lib/apis/manager/BILManager";
import { ChatController } from "react-lib/apps/IsHealth/Chat";
import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";
import ChatListController from "../patient-lib/ChatController";
import PaymentController from "patient-lib/PaymentController";
import DiagFormController from "./DiagFormController";
import { createFormDisplay } from "react-lib/apps/IsHealth/Common/Diag";
import MSGManager from "react-lib/apis/manager/MSGManager";
import REGManager from "react-lib/apis/manager/REGManager";

// UI component
import { IntlProvider } from "react-intl";

import MobMainIshealth from "react-lib/apps/IsHealth/IHMobile/MobMainIshealth";
import MobHistory from "react-lib/apps/IsHealth/IHMobile/MobHistory";
import MobLogin from "react-lib/apps/IsHealth/IHMobile/MobLogin";
import MobRegister from "react-lib/apps/IsHealth/IHMobile/MobRegister";
import IshealthPatientSelectAppointment from "react-lib/apps/IshealthAPP/IshealthPatientSelectAppointment";
import IshealthPatientCreateAppointment from "react-lib/apps/IshealthAPP/IshealthPatientCreateAppointment";
import IshealthAppointmentPatient from "react-lib/apps/IshealthAPP/IshealthAppointmentPatient";
import IshealthPatientEditAppointment from "react-lib/apps/IshealthAPP/IshealthPatientEditAppointment";
import CardManageAppointments from "react-lib/apps/QUE/CardManageAppointments";
import PatientPayment from "./PatientPayment";
import PatientPaymentDetail from "./PatientPaymentDetail";
import MobVerifyOtp, {
  ModalComponent,
} from "react-lib/apps/IsHealth/IHMobile/MobVerifyOtp";
import MobCreatePassword from "react-lib/apps/IsHealth/IHMobile/MobCreatePassword";
import MobEditProfile from "react-lib/apps/IsHealth/IHMobile/MobEditProfile";
import MobBottomBar from "react-lib/apps/IsHealth/IHMobile/MobBottomBar";
import MobHeader from "react-lib/apps/IsHealth/IHMobile/MobHeader";
import MobChatList from "react-lib/apps/IsHealth/IHMobile/MobChatList";
import MobForgotPassword from "react-lib/apps/IsHealth/IHMobile/MobForgotPassword";
import MobConsent from "react-lib/apps/IsHealth/IHMobile/MobConsent";
import MobEtc from "react-lib/apps/IsHealth/IHMobile/MobEtc";
import ChangPassword from "react-lib/apps/IsHealth/IHMobile/ChangPassword";

import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";
import { localizationEN, localizationTH } from 'react-lib/localization/localization';

declare global {
  var iosNative: any;
  var MobNative: any;
  var FB: any;
}

const LANGUAGE = {
  TH: "th-TH",
  EN_US: "en-US",
};
const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  TU: "tu",
  EGAT: "egat",
  PENGUIN: "penguin",
  IHMobile: "ishealth-mobile",
};

const PROJECT_NAME = {
  M_B_PLUS_TELEMED: "My B+ Telemed",
  TU: "TU",
  EGAT: "EGAT",
  IHMobile: "IsHealth",
};

const thMsg = {...localizationTH, ...thMessage}
const enMsg = {...localizationEN, ...enMessage}

class MobIsHealth extends Component<any, MobIsHealthInterface.State> {
  controller: MobIsHealthController;
  chatBoxRef: any;
  chatListRef: any;
  currentLocation: any;
  selectAddressRef: any;
  settingRef: any;
  drugAddressConfirmRef: any;
  chatManager: ChatManager | undefined;
  prxManager: PRXManager | undefined;
  bilManager: BILManager | undefined;
  coreManager: CoreManager | undefined;
  msgManager: MSGManager | undefined;
  regManager: REGManager | undefined;
  queManager: QUEManager | undefined;
  diagManager: DiagManager | undefined;

  ChatController: ChatController | undefined;
  diagFormController: DiagFormController | undefined;
  queueController: QueueController;
  chatListController: any;
  paymentController: any;
  channelList: any;
  lastReadMessage: any;
  openModal: boolean | undefined;
  loadingStatus: boolean | undefined;

  constructor(props: any) {
    super(props);
    var apiToken = Cookies.get("apiToken");
    var userId;
    this.openModal = false;
    //@ts-ignore
    globalThis.setState = this.setState.bind(this);
    this.controller = new MobIsHealthController(
      () => {
        return this.state;
      },
      (state: MobIsHealthInterface.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );
    // this.controller.setProp = SetProp(this, "");
    this.controller.setProp = SetProp(this, "");
    this.controller.cookies = Cookies;
    this.state = {
      projectName: Cookies.get("project_name"),
      login: apiToken !== null && apiToken !== undefined,
      profile: apiToken !== null && apiToken !== undefined,
      apiToken: apiToken,
      loadingStatus: false,

      // profile create
      // isCompleteProfile: false,
      openAllergyProfile: false,

      userId: userId,
      formPath: null,
      oaPath: null,
      rule: { id: null, name: "", content: {} },
      form: {},
      chatList: [],

      targetDiagRule: null,
      targetForm: {},

      chatName: "",
      chatDivision: 0,

      subscription: null,
      device_id: null,
      focusSelectMenu: "",

      lineLoginError: null,
      patientData: {},
      theme: "",
      openLogoutMod: false,
      openDeleteMod: false,
      openChangeThemeMod: false,
      openSettingMod: false,

      // For Chat, to prevent loading when open chat page
      channelList: [],
      chatLoading: false,
      chatSearchValue: "",
      language: Cookies.get("language") ? Cookies.get("language") : LANGUAGE.TH,

      backToApp: false,
      landingLoading: false,
      consentFormLang: "th-TH",

      alreadyRedirect: false,
      openVideoCallModal: false,
      videoCallRoom: "",
    };

    this.chatBoxRef = createRef();
    this.chatListRef = createRef();
    this.settingRef = createRef();
    this.selectAddressRef = createRef();
    this.drugAddressConfirmRef = createRef();
    this.currentLocation = createRef();
    this.chatManager = new ChatManager();
    this.prxManager = new PRXManager();
    this.coreManager = new CoreManager();
    this.bilManager = new BILManager();
    this.chatManager = new ChatManager();
    this.msgManager = new MSGManager();
    this.regManager = new REGManager();
    this.queManager = new QUEManager();
    this.diagManager = new DiagManager();

    this.chatListController = new ChatListController({
      chatManager: this.chatManager,
    });

    this.paymentController = new PaymentController({
      bilManager: this.bilManager,
    });

    // Controller legacy
    this.ChatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.queueController = new QueueController({
      queManager: this.queManager,
      prxManager: this.prxManager,
    });

    this.diagFormController = new DiagFormController({
      diagManager: this.diagManager,
    });

    vcMessenger.onMessage((message: any) => {
      // console.log("saika ;) message ----> ", message);
      if (message["data_message_type"] === "MESSAGE") {
        if (message["content_type"] === "vid_call") {
          console.log(
            "saika ~ MobIsHealth ~ vcMessenger.onMessage ~ message:",
            message
          );
        } else if (message["content_type"] === "end_call") {
          console.log(
            "saika ~ MobIsHealth ~ vcMessenger.onMessage ~ message:",
            message
          );
        }
        if (this.chatBoxRef && this.chatBoxRef.receivedMessage) {
          this.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }

        if (this.state.channelList.length === 0) {
          this.loadChannelList();
        }

        // if (this.chatListRef) {
        this.updateUnreadMessageCount(message);
        // }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.chatBoxRef && this.chatBoxRef.receivedMessageRead) {
          this.chatBoxRef.receivedMessageRead({
            chatChannelId: parseInt(message["channel_id"]),
            messageId: parseInt(message["message_id"]),
          });
        }

        if (this.chatBoxRef && this.chatBoxRef.receivedMessage) {
          this.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }

        // if (this.chatListRef) {
        //   this.updateUnreadMessageCount(message);
        // }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.chatBoxRef && this.chatBoxRef.loadPatientEncounter) {
          this.chatBoxRef.loadPatientEncounter();
        }
      }
    });
  }

  componentDidMount() {
    if (CONFIG.COMPANY === "BDMS") {
      this.setState({
        theme: THEME.PENGUIN,
        projectName: PROJECT_NAME.M_B_PLUS_TELEMED,
      });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      Cookies.set("theme", THEME.PENGUIN, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.M_B_PLUS_TELEMED, {
        path: "/",
      });
    } else if (CONFIG.COMPANY === "TU") {
      document.documentElement.setAttribute("data-theme", THEME.TU);
      Cookies.set("theme", THEME.TU, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.TU, {
        path: "/",
      });
    } else if (CONFIG.COMPANY === "EGAT") {
      document.documentElement.setAttribute("data-theme", THEME.EGAT);
      Cookies.set("theme", THEME.EGAT, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.EGAT, {
        path: "/",
      });
    } else if (CONFIG.COMPANY === "IH-M") {
      document.documentElement.setAttribute("data-theme", THEME.IHMobile);
      Cookies.set("theme", THEME.IHMobile, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.IHMobile, {
        path: "/",
      });
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      Cookies.set("theme", THEME.PENTA, { path: "/" });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    if (prefersDarkScheme.matches) {
      // set dark-theme
      document.documentElement.setAttribute("color-scheme", "dark");
    } else {
      // remove dark-theme
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (window.MobNative) {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    console.log("saika ~ componentDidUpdate:");
    const changedUserToken =
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken;

    if (changedUserToken) {
      console.log("saika ~ changedUserToken:", changedUserToken);
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId?.toString() || "", { path: "/" });
      this.loadChannelList();
      this.setWebsocket({ apiToken: this.state.apiToken });
      this.controller.handleEvent({
        message: "HandleCheckConsent",
        params: { history: prevProps.history, readOnly: true },
      });
    }
  }

  showBadge = (sum_unread: any) => {
    try {
      console.log("sum_unread = " + sum_unread);
      if (typeof window.iosNative !== "undefined") {
        window.iosNative.showBadge(sum_unread.toString());
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative.showBadge(sum_unread.toString());
      }
    } catch (e) {
      console.error("showBadge is not function...");
    }
  };

  loadChannelList = async () => {
    this.controller.setState({ loadingStatus: true });
    let apiToken = Cookies.get("apiToken");
    let userId = Cookies.get("userId");
    console.log("saika ~ userId:", userId);
    if ((this.state.channelList?.length || 0) === 0) {
      this.setState({ chatLoading: true });
    }
    const [data, error] = await this.chatListController.getChatChanel(
      apiToken,
      userId
    );
    let sum_unread = 0;
    if (data) {
      if (
        data &&
        data.items &&
        Array.isArray(data.items) &&
        data.items.length > 0
      ) {
        console.log("data ", data);
        data.items.forEach((item: any) => {
          sum_unread += item.unread_message_count;
        });
      }
      this.showBadge(sum_unread);
      // console.log(" getIcon data.items", data.items);
      let listCodeClinic = [
        CONFIG.DM_FEATURE,
        CONFIG.MOM_FEATURE,
        CONFIG.CHILD_FEATURE,
      ];
      let nofeature = data.items.filter(
        (item: any) => !listCodeClinic.includes(item.division_feature)
      );
      let feature = data.items.filter((item: any) =>
        listCodeClinic.includes(item.division_feature)
      );
      console.log("saika ~ feature:", feature);
      console.log("saika ~ nofeature:", nofeature);
      // if (feature?.length > 0 ) {
      //   const [data3, error3] = await this.chatListController.getChatChannelFromPatientHasDivision(apiToken, true);
      //   if (data3) {
      //     console.log(" getchatChannelFromPatientHasDivision data", data3)
      //   }
      // }

      this.setState({
        channelList: [...feature, ...nofeature],
        chatUnread: sum_unread,
      });
    } else {
      this.setState({ chatUnread: sum_unread });
      this.showBadge(sum_unread);
      this.setState({ channelList: [] });
    }
  };

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components: any) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component: any) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken ", props);
    var apiToken = this.state.apiToken || Cookies.get("apiToken");
    var userId = this.state.userId || Cookies.get("userId");

    this.setState({
      login: apiToken !== null && apiToken !== undefined,
      profile: apiToken !== null && apiToken !== undefined,
      apiToken: apiToken,
      userId: userId,
    });

    if (typeof window.iosNative !== "undefined") {
      try {
        const _apiToken = await window.iosNative._apiToken;
        const _userId = await window.iosNative._userId;
        if (_apiToken && _userId) {
          apiToken = _apiToken;
          userId = _userId;
          console.log(apiToken + " " + userId);
          Cookies.set("apiToken", _apiToken);
          Cookies.set("userId", _userId);
          this.setState({
            apiToken: _apiToken,
            userId: _userId,
            systemType: "IOS",
          });
          this.setWebsocket({ apiToken: _apiToken });
          this.getPatientDetail();
        } else {
          this.handleGoToLogin();
        }
      } catch (e) {
        console.error(e);
        console.log(e.message);
        this.handleGoToLogin();
      }
    } else if (typeof window.MobNative !== "undefined") {
      try {
        let userProfile = window.MobNative.getUser();
        const user = JSON.parse(userProfile);
        if (user.token) {
          apiToken = user.token;
          userId = user.profile.userId;
          console.log(apiToken + " " + user.token);
          Cookies.set("apiToken", user.token);
          Cookies.set("userId", user.profile.userId);
          this.setWebsocket({ apiToken: user.token });
          this.setState({
            apiToken: user.token,
            userId: user.profile.userId,
            systemType: "ANDROID",
          });
          this.getPatientDetail();
        } else {
          this.handleGoToLogin();
        }
      } catch (e) {
        console.error(e);
        console.log(e.message);
        this.handleGoToLogin();
      }
    } else {
      if (!apiToken) {
        console.log("apiToken", apiToken);
        this.handleGoToLogin();
      } else {
        this.setWebsocket({ apiToken: apiToken });
        this.getPatientDetail();
      }
    }
  };

  getPatientDetail = async () => {
    this.controller.handleEvent({
      message: "GetPatientDetail",
      params: {},
    });
    this.loadChannelList();
  };

  followDivisionProfile = async (divisionProfile: any) => {
    console.log("followDivisionProfile  divisionProfile", divisionProfile);
    let apiToken = this.state.apiToken
      ? this.state.apiToken
      : this.props.cookies.get("apiToken");
    this.setState({ chatLoading: true });
    const [data, error] =
      await this.chatListController.postFollowDivisionProfile(
        apiToken,
        divisionProfile
      );
    if (data) {
      // แทน ที่จะ reload เปิดไปเลย
      await this.loadChannelList();
      let channel = this.state.channelList?.find(
        (item: any) => item.division_profile_id === divisionProfile
      );
      console.log("followDivisionProfiledivisionProfile: ", divisionProfile);
      console.log("followDivisionProfile channel?.id: ", channel?.id);

      if (channel?.id) {
        let gotoItem = {
          id: channel?.id,
          division_code: channel?.division_code,
        };
        this.handleOnFeatureChannelSelected(gotoItem);
      }

      // this.handleOnFeatureChannelSelected(item)

      // let items = []
      // if ( data && data.items && Array.isArray(data.items) && data.items.length > 0) {
      //   console.log("data ", data)
      //   items = data.items.filter(item => item.feature)
      //   items.forEach((item) => {
      //     sum_unread += item.unread_message_count
      //   })
      // }
      // this.setState({ unfollowChannelList: items, chatLoading: false })
    } else {
      console.log("test2 ");

      // this.setState({ unfollowChannelList: [],chatLoading: false })
    }
    // this.setState({chatLoading: false})
  };

  handleOnFeatureChannelSelected = (item: any) => {
    let division_code = item.division_code;
    let division_feature = item.division_feature;

    // console.log(" handleOnFeatureChannelSelected", item)

    switch (division_feature) {
      case CONFIG.DM_FEATURE:
        // console.log(" my ChatChannel from DMMainPatient to DM", item.id)
        // // let pathDM = item.id ? `&chatChannel=${item.id}` : ""
        // // window.location.assign(CONFIG.DM_BPLUS_URL + pathDM)
        // window.location.assign(CONFIG.DM_BPLUS_URL)
        break;
      case CONFIG.MOM_FEATURE:
        // console.log(" my ChatChannel from MainPatient to Mom", item.id)
        // // let pathMom = item.id ? `&chatChannel=${item.id}` : ""
        // // window.location.assign(CONFIG.MOM_BPLUS_URL + pathMom)
        // window.location.assign(CONFIG.MOM_BPLUS_URL)
        break;
      case CONFIG.CHILD_FEATURE:
        // console.log(" my ChatChannel from MainPatient to child", item.id)
        // // let pathChild = item.id ? `&chatChannel=${item.id}` : ""
        // // window.location.assign(CONFIG.CHILD_BPLUS_URL + pathChild)
        // window.location.assign(CONFIG.CHILD_BPLUS_URL)

        break;
      default:
        break;
    }
  };

  updateUnreadMessageCount = (message: any) => {
    let chatUnread = 0;
    const update = (this.state.channelList || []).map((item: any) => ({
      ...item,
      unread_message_count:
        item.id === message.channel_id
          ? (item.unread_message_count || 0) + 1
          : item.unread_message_count,
    }));

    update.forEach((item: any) => {
      chatUnread = chatUnread + item.unread_message_count;
    });

    this.setState({ channelList: [...update], chatUnread: chatUnread });
  };

  handleSetFocusSelect = () => {
    this.setState({ focusSelectMenu: "" });
  };

  handleGoToLogin = () => {
    console.log("apiToken props", this.props);
    this.props.history.push("/login");
  };

  handleLogout = () => {
    console.log(" handleLogout");
    // Logout firebase
    this.controller.app.auth().signOut();
    serviceWorker.unregister();

    this.controller.handleEvent({
      message: "HandleSignOut",
      params: {},
    });
    console.log("unregister service worker");
    // Remove data
    Cookies.remove("fullname", { path: "/" });
    Cookies.remove("username", { path: "/" });
    Cookies.remove("apiToken", { path: "/" });
    Cookies.remove("division_id", { path: "/" });
    Cookies.remove("userId", { path: "/" });
    Cookies.remove("project_name", { path: "/" });
    Cookies.remove("firstPageAns", { path: "/" });
    let params = new URLSearchParams(this.props.location.search);

    console.log(
      "handleLogout this.props.location.pathname: ",
      this.props.location.pathname
    );
    console.log("handleLogout params: ", params);
    this.setState({
      apiToken: "",
      userId: "",
      login: false,
      loginInfo: {},
      registerInfo: {error:{}},
      profileInfo: {},
    });

    if (typeof window.MobNative !== "undefined") {
      window.MobNative?.logout?.();
    } else if (typeof window.iosNative !== "undefined") {
      window.iosNative?.logout?.();
    }

    window.history.replaceState({}, "");
    this.props.history.replace("", null);

    this.props.history.push({
      pathname: "/login",
      state: {},
    });
  };

  getPropRoute = (pathRoute: string) => {
    const clearString = pathRoute.replace(/(\?|\/$)/gi, "");
    const regFormat = clearString.replace(
      /\/:\w+/g,
      (...res) => `(/(?<${res[0].slice(2)}>\\w+)|)`
    );
    const pathName = this.props.location.pathname;
    const exec = new RegExp(regFormat, "i").exec(pathName);
    const { groups } = exec || { groups: {} };
    const search = pathName.match(/\?.*/)?.[0];

    if (search) {
      this.props.location.search = search;
      this.props.history.location.search = search;
      this.props.location.pathname = pathName.replace(/\?.*/i, "");
      this.props.history.location.pathname = pathName.replace(/\?.*/i, "");
    }

    return {
      match: { params: groups },
      location: this.props.location,
      history: this.props.history,
    };
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleGoBackWithNative = (backTo: string) => {
    if (window.MobNative?.checkGoBack) {
      window.MobNative?.checkGoBack(backTo);
    } else if (window.iosNative?.checkGoBack) {
      window.iosNative?.checkGoBack(backTo);
    } else {
      this.props.history.goBack();
    }
  };

  handleNavigationMessage = ({
    content = "",
    contentPayload = "",
    contentType,
    channelId,
  } = {}) => {
    if (content.includes("{หน้าโปรไฟล์}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(/{หน้าโปรไฟล์}/g, "หน้าโปรไฟล์"),
          }}
        />
      );
    } else if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{ตารางออกตรวจผู้ให้คำปรึกษา}/g,
              "ตารางออกตรวจผู้ให้คำปรึกษา"
              // "<a href='/Appointment'>ตารางออกตรวจผู้ให้คำปรึกษา</a>"
            ),
          }}
        />
      );
    } else if (content.includes("{กดเพื่อยืนยันการจัดส่งยา}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{กดเพื่อยืนยันการจัดส่งยา}/g,
              "กรุณาระบุที่อยู่จัดส่ง"
            ),
          }}
        />
      );
    } else if (content.includes("{ดูประวัติการนัดหมาย}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{ดูประวัติการนัดหมาย}/g,
              "ดูประวัติการนัดหมาย"
              // "<a href='/VCAppointmentView'>ดูประวัติการนัดหมาย</a>"
            ),
          }}
        />
      );
    } else if (contentType === "bill") {
      return (
        <div
          onClick={() => {
            setPaymentID(contentPayload.invoice_id);
            setOpenPayment(true);
          }}
        >
          <a nohref>{content}</a>
        </div>
      );
    } else if (contentType === "patient_appointment") {
      return (
        <CardManageAppointments
          contentPayload={contentPayload}
          setProps={this.controller.setProp}
          apiToken={this.state.apiToken}
          patientData={this.state.patientData}
          history={this.props.history}
          onEvent={this.controller.handleEvent}
          remindInComing={content.includes("Reminder incoming appointment.")}
          patientAppointmentList={this.state.patientAppointmentList}
          loadingStatus={this.state.loadingStatus}
        />
      );
    } else {
      return content;
    }
  };

  handleSetChatName = ({ name }: { name?: string; division?: number } = {}) => {
    this.setState({ chatName: name });
  };

  render() {
    return (
      <IntlProvider
        locale="th-TH"
        messages={this.state.language?.includes("TH") ? thMsg : enMsg}
        // messages={flattenMessages(messages["en-US"])}
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
      >
        <div style={{ height: "100vh" }}>
          <MainVideoCall.Main
            // onEvent={this.controller.handleEvent}
            // setProp={this.controller.setProp}
            // setFullScreen={true}
            openVideoCallModal={this.state.openVideoCallModal}
            videoCallRoom={this.state.videoCallRoom}
            isPatient={true}
            username={this.state.patientData.patient_name ?? ""}
            onCloseVideoCall={() =>
              this.setState({
                openVideoCallModal: false,
                videoCallRoom: "",
              })
            }
          />
          <Switch>
            {/*  section register box start */}
            <Route
              path="/consent-form/:id/:page?/"
              render={(props) => (
                <MobConsent
                  onEvent={this.controller.handleEvent}
                  apiToken={this.state.apiToken || ""}
                  consentsList={undefined}
                  controller={this.diagFormController}
                  selectHospital={undefined}
                  selectHospitalCode={undefined}
                  selectHospitalName={undefined}
                  menuBar={
                    <MobHeader
                      title="แบบฟอร์มขอความยินยอม"
                      history={props.history}
                    />
                  }
                  onSetBackToApp={(value: any) => {
                    console.log(" Value", value);
                  }}
                  {...props}
                  onFormLanguageChange={(lang) => {
                    console.log(lang, "lang");
                    let language = "th-TH";
                    if (
                      lang &&
                      typeof lang === "string" &&
                      ["en", "en-us", "eng", "english"].includes(
                        lang.toLowerCase()
                      )
                    ) {
                      language = "en-US";
                    }
                    this.setState({ consentFormLang: language });
                  }}
                  patientData={this.state.patientData}
                  setProps={this.controller.setProp}
                  noApiToken={this.getApiToken}
                />
              )}
            />
            <Route
              path="/login/"
              render={(props) => (
                <MobLogin
                  onEvent={this.controller.handleEvent}
                  history={props.history}
                  loginInfo={this.state.loginInfo}
                  loading={this.state.loading}
                  setProps={this.controller.setProp}
                  devMode={this.state.devMode}
                />
              )}
            />
            <Route
              path="/register/"
              render={(props) => (
                <MobRegister
                  onEvent={this.controller.handleEvent}
                  history={props.history}
                  loading={this.state.loading}
                  register={this.state.registerInfo}
                />
              )}
            />
            <Route
              path="/create-password/"
              render={(props) => (
                <MobCreatePassword
                  onEvent={this.controller.handleEvent}
                  history={props.history}
                  loading={this.state.loading}
                  register={this.state.registerInfo}
                />
              )}
            />
            <Route
              path="/edit-profile/"
              render={(props) => (
                <MobEditProfile
                  onEvent={this.controller.handleEvent}
                  history={props.history}
                  setProps={this.controller.setProp}
                  loading={this.state.loading}
                  profileInfo={this.state.profileInfo}
                  dropDownOption={this.state.dropDownOption}
                />
              )}
            />
            <Route
              path="/verify-otp/"
              render={(props) => (
                <MobVerifyOtp
                  onEvent={this.controller.handleEvent}
                  history={props.history}
                  setProps={this.controller.setProp}
                  loading={this.state.loading}
                  errorCheck={this.state.errorCheck}
                  register={this.state.registerInfo}
                  refCode={this.state.refCode}
                />
              )}
            />
            <Route
              path="/forgot-password/"
              render={(props) => (
                <MobForgotPassword
                  onEvent={this.controller.handleEvent}
                  history={props.history}
                  setProps={this.controller.setProp}
                  loading={this.state.loading}
                  errorCheck={this.state.errorCheck}
                  errorMassage={this.state.errorMessage}
                  register={this.state.registerInfo}
                  refCode={this.state.refCode}
                />
              )}
            />
            {/*  section register box end */}
            {/*  section appointmentIH box start */}
            <Route
              path="/appointment"
              render={(props) => (
                <div className="content-header">
                  <MobHeader
                    title="นัดหมาย"
                    history={props.history}
                    hideButton={true}
                  />
                  <IshealthAppointmentPatient
                    history={this.props.history}
                    location={this.props.location}
                    noApiToken={this.getApiToken}
                    apiToken={this.state.apiToken}
                    loadingStatus={this.state.loadingStatus}
                    patientData={this.state.patientData}
                    controller={this.queueController}
                    handleGoBack={this.handleGoBack}
                    setProps={this.controller.setProp}
                    onEvent={this.controller.handleEvent}
                    patientAppointmentList={this.state.patientAppointmentList}
                  />
                  <MobBottomBar
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    initialInnerScreen={this.state.initialInnerScreen}
                    activeItem="appointment"
                    chatUnread={this.state.chatUnread}
                  />
                </div>
              )}
            />
            <Route
              path="/appointmentIH"
              render={(props) => {
                return (
                  <IshealthPatientSelectAppointment
                    {...this.getPropRoute("appointmentIH/")}
                    noApiToken={this.getApiToken}
                    apiToken={this.state.apiToken}
                    controller={this.props.queueController}
                    location={this.props.location}
                    divisionList={this.state.divisionList}
                    doctorList={this.state.doctorList}
                    setProps={this.controller.setProp}
                    onEvent={this.controller.handleEvent}
                    appointmentData={this.state.appointmentData}
                  />
                );
              }}
            />
            <Route
              exact
              path="/createAppointmentIH/:appointStatus/:appointId"
              render={(props) => {
                return (
                  <div className="content-header">
                    <div
                      style={{
                        width: "100vw",
                        overflow: "auto",
                      }}
                    >
                      <IshealthPatientCreateAppointment
                        {...this.getPropRoute(
                          "/createAppointmentIH/:appointStatus/:appointId/"
                        )}
                        noApiToken={this.getApiToken}
                        apiToken={this.state.apiToken}
                        controller={this.props.queueController}
                        handleGoBack={this.handleGoBack}
                        appointmentData={this.state.appointmentData}
                        createAppointment={this.state.createAppointment}
                        divisionList={this.state.divisionList}
                        doctorList={this.state.doctorList}
                        timeDivision={this.state.timeDivision}
                        userProfile={this.state.patientData}
                        setProp={this.controller.setProp}
                        onEvent={this.controller.handleEvent}
                        errorMessage={this.state.errorMessage}
                        successMessage={this.state.successMessage}
                        patientAppointmentList={
                          this.state.patientAppointmentList
                        }
                        duplicateAPMList={this.state.duplicateAPMList}
                        loadingStatus={this.state.loadingStatus}
                      />
                    </div>
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/editAppointmentIH/:appointId"
              render={(props) => {
                return (
                  <IshealthPatientEditAppointment
                    {...this.getPropRoute("/editAppointmentIH/:appointId")}
                    noApiToken={this.getApiToken}
                    apiToken={this.state.apiToken}
                    controller={this.props.queueController}
                    handleGoBack={this.handleGoBack}
                    appointmentData={this.state.appointmentData}
                    createAppointment={this.state.createAppointment}
                    divisionList={this.state.divisionList}
                    doctorList={this.state.doctorList}
                    timeDivision={this.state.timeDivision}
                    setProp={this.controller.setProp}
                    onEvent={this.controller.handleEvent}
                    errorMessage={this.state.errorMessage}
                    successMessage={this.state.successMessage}
                  />
                );
              }}
            />
            {/*  section appointmentIH box end */}
            {/*  section history box start */}
            <Route
              path="/history"
              render={(props) => (
                <div className="content-header">
                  <MobHeader
                    title="History"
                    history={props.history}
                    hideButton={true}
                  />
                  <MobHistory
                    onEvent={this.controller.handleEvent}
                    history={props.history}
                    setProp={this.controller.setProp}
                    noApiToken={this.getApiToken}
                    apiToken={this.state.apiToken}
                    consentFinished={this.state.consentFinished}
                  />
                  <MobBottomBar
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    initialInnerScreen={this.state.initialInnerScreen}
                    activeItem="history"
                    chatUnread={this.state.chatUnread}
                  />
                </div>
              )}
            />
            {/*  section history box end */}
            {/*  section chat box start */}
            <Route
              path="/chat"
              render={(props) => (
                <div className="content-header">
                  <MobHeader
                    title="Chat"
                    history={props.history}
                    hideButton={true}
                  />
                  <MobChatList
                    history={props.history}
                    apiToken={this.state.apiToken}
                    userId={this.state.userId}
                    chatBoxRef={(ref) => (this.chatBoxRef = ref)}
                    chatListRef={(ref) => (this.chatListRef = ref)}
                    chatController={this.ChatController}
                    channelList={this.state.channelList}
                    loadChannelList={this.loadChannelList}
                    followDivisionProfile={this.followDivisionProfile}
                    unfollowChannelList={undefined}
                    setProp={this.controller.setProp}
                    noApiToken={this.getApiToken}
                    chatUnread={this.state.chatUnread}
                  />
                  <MobBottomBar
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    initialInnerScreen={this.state.initialInnerScreen}
                    activeItem="chat"
                    chatUnread={this.state.chatUnread}
                  />
                </div>
              )}
            />
            <Route
              path="/chatChannel/:chatChannelId/"
              render={(props) => (
                <div className="content-header">
                  <MobHeader
                    title={this.state.chatName}
                    history={props.history}
                    leftIconClick={() => this.handleGoBackWithNative("chat")}
                  />
                  <div
                    className="content-detail"
                    style={{ background: "#E3F3F9" }}
                  >
                    <div>
                      <ChatBox
                        {...this.getPropRoute("/chatChannel/:chatChannelId/")}
                        division={this.state.chatDivision}
                        ref={(ref) => (this.chatBoxRef = ref)}
                        noApiToken={this.getApiToken}
                        apiToken={this.state.apiToken}
                        userId={this.state.userId}
                        patientData={this.state.patientData}
                        controller={this.ChatController}
                        useNormalTextAreaKey={true}
                        onSetChannelName={this.handleSetChatName}
                        onNavigationMessage={this.handleNavigationMessage}
                        // onSetBackToApp={(value: boolean) => {
                        //   this.setState({ backToApp: value });
                        // }}
                        // disabledScrollIntoView={this.state.disabledScrollIntoView}
                        onOpenVideoCall={(url: string) =>
                          this.setState({
                            openVideoCallModal: true,
                            videoCallRoom: url,
                          })
                        }
                        keyboardSize={this.state.keyboardSize}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
            {/*  section chat box end */}
            {/*  section etc setting box start */}
            <Route
              path="/etc"
              render={(props) => (
                <div className="content-header">
                  <MobHeader
                    title="เมนูอื่นๆ"
                    history={props.history}
                    hideButton={true}
                  />
                  <MobEtc
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    setProps={this.controller.setProp}
                    systemType={this.state.systemType}
                  />
                  <MobBottomBar
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    initialInnerScreen={this.state.initialInnerScreen}
                    activeItem="etc"
                    chatUnread={this.state.chatUnread}
                  />
                </div>
              )}
            />
            <Route
              path="/chang_password"
              render={(props) => (
                <div className="content-header">
                  <MobHeader title="เปลี่ยนรหัสผ่าน" history={props.history} />
                  <ChangPassword
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    loading={this.state.loading}
                    errorMessage={this.state.errorMessage}
                    setProps={this.controller.setProp}
                    logout={this.handleLogout}
                  />
                </div>
              )}
            />
            {/*  section etc setting box end */}
            {/*  section main screen */}
            <Route
              path="/"
              render={(props) => (
                <div className="content-header">
                  <MobHeader
                    title=""
                    history={props.history}
                    hideButton={true}
                    logOut={true}
                    onclickLogout={this.handleLogout}
                    patientData={this.state.patientData}
                    showProfile={true}
                  />
                  <MobMainIshealth
                    onEvent={this.controller.handleEvent}
                    history={props.history}
                    noApiToken={this.getApiToken}
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    useOtherURL="https://www.sapienspainhospital.com/"
                  />
                  <MobBottomBar
                    history={props.history}
                    onEvent={this.controller.handleEvent}
                    initialInnerScreen={this.state.initialInnerScreen}
                    activeItem="main"
                    chatUnread={this.state.chatUnread}
                  />
                </div>
              )}
            />
          </Switch>
        </div>
        <ModalComponent
          open={this.openModal || false}
          onClose={() => {
            this.openModal = false;
          }}
          title={"Sign out"}
          labelDetail={"Are you sure you want to sign out?"}
          leftText={"cancel"}
          leftOnClick={() => {
            this.openModal = false;
          }}
          rightText={"Yes"}
          rightOnClick={() => {}}
        />
      </IntlProvider>
    );
  }
}
export default withRouter(MobIsHealth);
