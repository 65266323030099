import React, { useState } from "react";
import {
  Divider,
  Form,
  Icon,
  Image,
  Input,
  InputProps,
  Button,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import { registerInfo } from "./TypeModal";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import InputFiledCircular from "./InputFiledCircular";
import { useIntl } from "react-intl";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type MobCreatePasswordTypes = {
  onEvent?: any;
  history: any;
  loading?: boolean;
  register?: registerInfo;
};

const MobCreatePassword = (props: MobCreatePasswordTypes) => {
  const intl = useIntl();
  console.log("saika ~ MobCreatePassword props:", props);
  const [openOTP, setOpenOTP] = useState<boolean>(false);

  const handleOpenVerify = () => {
    // props.history.push("/edit-profile/");
    if (!props.loading) {
      props.onEvent({
        message: "HandleRegister",
        params: { viewRegister: "confirmPassword", history: props.history },
      });
    }
  };

  const handleSetHide = (type: string) => {
    if (type === "hideConfirmPassword") {
      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: {
          key: "hideConfirmPassword",
          value: !props.register?.hideConfirmPassword,
        },
      });
    } else {
      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: {
          key: "hidePassword",
          value: !props.register?.hidePassword,
        },
      });
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.register?.error || {};
    if (props.register?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetLoginInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleCheckField = () => {
    let disable = false;
    if (
      props.register?.error?.password ||
      props.register?.error?.confirmPassword
    ) {
      disable = true;
    }
    return disable;
  };

  return (
    <div className="content-header">
      <MobHeader title={intl.formatMessage({ id: "สร้างรหัสผ่าน" })} history={props.history} />

      {/* Form Password */}
      <div className="content-detail">
        <div style={{ padding: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Image src="/images/ishealth/register-images.png" />
          </div>

          <div style={{ margin: "20px 0px" }}>
            <div
              style={{
                fontSize: "14px",
                color: "var(--primary-font-color)",
                whiteSpace: "pre-line",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {
                "เป็นตัวอักษรภาษาอังกฤษตัวใหญ่ตัวเล็กและตัวเลข \n ความยาวไม่น้อยกว่า 8 ตัวอักษร"
              }
            </div>
          </div>

          <InputFiledCircular
            id="password"
            typeIcon="PASSWORD"
            error={
              props.register?.error?.password || ""
            }
            label={intl.formatMessage({ id: "รหัสผ่าน" })}
            value={props.register?.password || ""}
            type={"password"}
            hindPassword={props.register?.hidePassword}
            onClickHind={() => handleSetHide("hidePassword")}
            onChange={(e: any, v: InputProps) => {          
              props.onEvent({
                message: "HandleSetValueRegisterInfo",
                params: { key: "password", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("password")}
          />

          <InputFiledCircular
            id="confirm-password"
            typeIcon="PASSWORD"
            error={
              props.register?.error?.confirmPassword || ""
            }
            label={intl.formatMessage({ id: "ใส่รหัสผ่านอีกครั้ง" })}
            value={props.register?.confirmPassword || ""}
            type={"password"}
            hindPassword={props.register?.hideConfirmPassword}
            onClickHind={() => handleSetHide("hideConfirmPassword")}
            onChange={(e: any, v: InputProps) => {
              props.onEvent({
                message: "HandleSetValueRegisterInfo",
                params: { key: "confirmPassword", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("confirmPassword")}
          />

          <div className="div-center-bottom">
            <div className="ishealth-buttom">
              <Button
                disabled={handleCheckField()}
                loading={props.loading}
                onClick={() => handleOpenVerify()}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MobCreatePassword.displayname = "CreatePassword";
export default MobCreatePassword;
